import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProjectList from './components/ProjectList';
import ProjectDetailPage from './components/ProjectDetailPage';

const App = () => {
  const projects = [
    {
      id: 1,
      title: 'Cube Design',
      // description: 'A contemporary home design',
      thumbnail: '/img/cube.png',
      portfolioPdf: '/pdf/cube-design.pdf',
      images: ['/img/cube.png', '/img/cube2.png']
    },
    {
      id: 2,
      title: 'Egronomics and Anthropology',
      // description: 'A sustainable workplace solution',
      thumbnail: '/img/Rational.png',
      portfolioPdf: '/pdf/split-2.pdf',
      images: ['/img/Rational.png']
    },
    {
      id: 3,
      title: 'Sitting Pavilion',
      // description: 'A sustainable workplace solution',
      thumbnail: '/img/pavilion.png',
      portfolioPdf: '/pdf/split-3.pdf',
      images: ['/img/pavilion.png']
    },

    {
      id: 4,
      title: 'Staircase & Gallery Design',
      // description: 'A contemporary home design',
      thumbnail: '/img/stair.png',
      portfolioPdf: '/pdf/Portfolio-1.pdf',
      images: ['/img/stair.png']
    },
    {
      id: 5,
      title: 'Boutique Hotel',
      // description: 'A sustainable workplace solution',
      thumbnail: '/img/p1.jpg',
      portfolioPdf: '/pdf/Boutique Hotel.pdf',
      images: ['/img/p1.jpg', '/img/p2.jpg', '/img/p3.jpg']
    },
    {
      id: 6,
      title: 'Wellness Center',
      // description: 'A sustainable workplace solution',
      thumbnail: '/img/well1.jpg',
      portfolioPdf: '/pdf/Portfolio 2.pdf',
      images: ['/img/well1.jpg', '/img/well2.jpg', '/img/well3.jpg','/img/well4.jpg', '/img/well5.jpg', '/img/well6.jpg','/img/well7.jpg', '/img/well8.jpg', '/img/well9.jpg','/img/well10.jpg', '/img/well11.jpg', '/img/well12.jpg']
    },
  ];
  
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <header className="bg-gray-800 text-white py-4">
          <div className="container mx-auto px-4">
            <Link to="/" className="text-2xl font-bold">Fatimatu Bah Portfolio</Link>
          </div>
        </header>
        <main className="flex-grow container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<ProjectList projects={projects} />} />
            <Route 
              path="/project/:id" 
              element={<ProjectDetailPage projects={projects} />} 
            />
          </Routes>
        </main>
        <footer className="bg-gray-800 text-white py-4 mt-8">
          <div className="container mx-auto px-4 text-center">
            © 2024 Fatimatu Bah. All rights reserved.
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;