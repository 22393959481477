import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project }) => (
  <Link to={`/project/${project.id}`} className="block">
    <div className="bg-white rounded-lg shadow-md p-4 cursor-pointer hover:shadow-lg transition-shadow duration-300">
      <img src={project.thumbnail} alt={project.title} className="w-full h-48 object-cover rounded-md mb-4" />
      <h2 className="text-xl font-semibold mb-2">{project.title}</h2>
      <p className="text-gray-600">{project.description}</p>
    </div>
  </Link>
);

const ProjectList = ({ projects }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {projects.map(project => (
      <ProjectCard key={project.id} project={project} />
    ))}
  </div>
);

export default ProjectList;