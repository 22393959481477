import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.js`;

const ProjectDetailPage = ({ projects }) => {
  const { id } = useParams();
  const project = projects.find(p => p.id === parseInt(id));

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const [pdfWidth, setPdfWidth] = useState(800);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    const updatePdfWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        setPdfWidth(Math.min(containerWidth - 32, 1200));
      }
    };

    updatePdfWidth();
    window.addEventListener('resize', updatePdfWidth);

    return () => window.removeEventListener('resize', updatePdfWidth);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setError(null);
    setIsLoading(false);
  }

  function onDocumentLoadError(error) {
    console.error('Error loading PDF:', error);
    setError(`Failed to load PDF: ${error.message}`);
    setIsLoading(false);
  }

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="max-w-4xl mx-auto" ref={containerRef}>
      <Link to="/" className="text-blue-500 hover:text-blue-700 mb-4 inline-block">&larr; Back to Projects</Link>
      <h1 className="text-4xl font-bold mb-8 text-center">{project.title}</h1>
      <Tab.Group onChange={setSelectedTab}>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl mb-8">
          <Tab className={({ selected }) =>
            `w-full py-2.5 text-sm font-medium leading-5 text-blue-700 rounded-lg
             focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
             ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
          }>
            PDF Portfolio
          </Tab>
          <Tab className={({ selected }) =>
            `w-full py-2.5 text-sm font-medium leading-5 text-blue-700 rounded-lg
             focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
             ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
          }>
            Image Gallery
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel className="bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60">
            {selectedTab === 0 && (
              <>
                {isLoading && (
                  <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
                  </div>
                )}
                {error ? (
                  <div className="text-red-500">{error}</div>
                ) : (
                  <div className="flex flex-col items-center">
                    <Document
                      file={project.portfolioPdf}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={onDocumentLoadError}
                      loading={<div className="text-center">Loading PDF...</div>}
                      options={{
                        cMapUrl: `${process.env.PUBLIC_URL}/pdfjs/cmaps/`,
                        cMapPacked: true,
                      }}
                    >
                      <Page pageNumber={pageNumber} width={pdfWidth} />
                    </Document>
                    {!isLoading && (
                      <>
                        <p className="mt-4 mb-2">
                          Page {pageNumber} of {numPages}
                        </p>
                        <div className="flex justify-between mt-2 w-full max-w-md">
                          <button 
                            onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))} 
                            disabled={pageNumber <= 1}
                            className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
                          >
                            Previous
                          </button>
                          <button 
                            onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages || Infinity))} 
                            disabled={pageNumber >= numPages}
                            className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
                          >
                            Next
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </Tab.Panel>
          <Tab.Panel className="bg-white rounded-xl p-3 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60">
            <Carousel showThumbs={false} infiniteLoop useKeyboardArrows dynamicHeight>
              {project.images.map((image, index) => (
                <div key={index} className="h-96">
                  <img src={image} alt={`Project Image ${index + 1}`} className="object-contain h-full w-full" />
                </div>
              ))}
            </Carousel>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ProjectDetailPage;